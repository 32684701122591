.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.notification-item {
  cursor: pointer;
  &:hover {
    transition: all ease-in-out 0.3s;
    background: #bae0ee;
  }
}
.notification-unseen {
  transition: all ease-in-out 0.3s;
  background: #bae0ee;
}

.sign-container {
  text-align: center;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.con-dau {
  text-align: center;
  /* width: 50%; */
  font-weight: 600;
}

.chu-ky {
  text-align: center;
  width: 50%;
  font-weight: 600;
}

.ct-table {
  width: 100%;
}

.ct-table td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 5px;
}

.ct-table .cl-1 {
  font-weight: bold;
}

.ct-table .cl-2 {
  font-weight: 500;
  color: rgb(240, 21, 21);
}
